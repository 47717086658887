import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import ScrollToTop from "./generic/scrollToTop";

import Homepage from "./HomePage";
import AboutPage from "./About";
import EventPage from "./Projects";
import DonatePage from "./Donate";
import SolidatiryArticlesPage from "./SolidatiryArticles";
import ProjectApoioEducacionalPage from "./ProjectApoioEducacional";
import ProjectSustentabilidadeAlimentarPage from "./ProjectSustentabilidadeAlimentar";
import ProjectCentroComunitarioAquarela from "./ProjectCentroComunitarioAquarela";
import PartnersPage from "./Partners";
import ErrorPage from "./Error";
import ContactPage from "./Contact";

const Router = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route exact path="/" element={<Homepage />} />
      <Route path="inicio" element={<Homepage />} />
      <Route path="quem-somos" element={<AboutPage />} />
      <Route path="projetos" element={<EventPage />} />
      <Route
        path="projetos/sustentabilidade-alimentar"
        element={<ProjectSustentabilidadeAlimentarPage />}
      />
      <Route
        path="projetos/apoio-educacional"
        element={<ProjectApoioEducacionalPage />}
      />
      <Route
        path="projetos/centro-comunitario-aquarela"
        element={<ProjectCentroComunitarioAquarela />}
      />
      <Route path="donativo" element={<DonatePage />} />
      <Route path="artigos-solidarios" element={<SolidatiryArticlesPage />} />
      <Route path="parcerias" element={<PartnersPage />} />
      <Route path="contactos" element={<ContactPage />} />
      <Route path="404" element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="404" replace />} />
    </Routes>
  </BrowserRouter>
);
export default Router;
