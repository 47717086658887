import React from "react";
import ImageCustom from "../../components/Image";
import "./style.css";

/**
 *
 * Associação Aquarela - https://web.facebook.com/profile.php?id=100079511740375
 * My Moyo - web.facebook.com/bymymoyo
 * Chiky Gentil - https://web.facebook.com/profile.php?id=100064532778669
 * YogaBraga - https://web.facebook.com/bragayoga
 * Compra solidária - https://www.comprasolidaria.pt
 */

const Causes = ({ description, partnersList }) => (
  <div className="case-area section-padding">
    <div className="container">
      <div className="col-lg-8 offset-lg-2">
        <div
          className="section-title section-title2 text-center"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="row">
        {partnersList &&
          partnersList.map((partner, index) => (
            <div className="col-lg-4 col-md-6 col-12" key={`partner-${index}`}>
              <div className="cause-item">
                <div className="cause-top">
                  <div className="cause-img">
                    <ImageCustom src={partner.logo} alt={partner.name} />
                  </div>
                </div>
                <div className="cause-text">
                  <h3>
                    <a
                      href={partner.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {partner.name}
                    </a>
                  </h3>
                  <p>{partner.description}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  </div>
);

export default Causes;
