import React, { useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";

const menus = [
  {
    title: "Início",
    link: "/",
  },
  {
    title: "Quem Somos",
    link: "/quem-somos",
  },
  {
    title: "Projetos",
    link: "/projetos",
  },
  {
    title: "Como ajudar",
    link: "/donativo",
  },
  {
    title: "Artigos Solidários",
    link: "/artigos-solidarios",
  },
  {
    title: "Parcerias",
    link: "/parcerias",
  },
  {
    title: "Contactos",
    link: "/contactos",
  },
];

const MobileMenu = () => {
  const [isMenuShow, setIsMenuShow] = useState(false);
  const [isOpen, setIsOpen] = useState(0);

  const menuHandler = () => {
    setIsMenuShow({
      isMenuShow: !isMenuShow,
    });
  };

  return (
    <>
      <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
        {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

        <ul className="responsivemenu">
          {menus.map((item, index) => {
            return (
              <li key={`menu-${index}`}>
                {item.submenu ? (
                  <p onClick={setIsOpen(index)}>
                    {item.title}
                    {item.submenu && (
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    )}
                  </p>
                ) : (
                  <Link to={item.link}>{item.title}</Link>
                )}
                {item.submenu && (
                  <Collapse isOpen={index === isOpen}>
                    <Card>
                      <CardBody>
                        <ul>
                          {item.submenu.map((submenu) => (
                            <li key={submenu.id}>
                              <Link className="active" to={submenu.link}>
                                {submenu.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={menuHandler}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
      {isMenuShow && <div className="backgroundmenu" onClick={menuHandler} />}
    </>
  );
};

export default MobileMenu;

// export default class MobileMenu extends Component {
//   state = {
//     isMenuShow: false,
//     isOpen: 0,
//   };

//   menuHandler = () => {
//     this.setState({
//       isMenuShow: !this.state.isMenuShow,
//     });
//   };

//   setIsOpen = (id) => () => {
//     this.setState({
//       isOpen: id === this.state.isOpen ? 0 : id,
//     });
//   };

//   render() {
//     const { isMenuShow, isOpen } = this.state;

//     return (
//       <div>
//         <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
//           {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

//           <ul className="responsivemenu">
//             {menus.map((item, index) => {
//               return (
//                 <li key={`menu-${index}`}>
//                   {item.submenu ? (
//                     <p onClick={this.setIsOpen(index)}>
//                       {item.title}
//                       {item.submenu && (
//                         <i className="fa fa-angle-right" aria-hidden="true"></i>
//                       )}
//                     </p>
//                   ) : (
//                     <Link to={item.link}>{item.title}</Link>
//                   )}
//                   {item.submenu && (
//                     <Collapse isOpen={index === isOpen}>
//                       <Card>
//                         <CardBody>
//                           <ul>
//                             {item.submenu.map((submenu) => (
//                               <li key={submenu.id}>
//                                 <Link className="active" to={submenu.link}>
//                                   {submenu.title}
//                                 </Link>
//                               </li>
//                             ))}
//                           </ul>
//                         </CardBody>
//                       </Card>
//                     </Collapse>
//                   )}
//                 </li>
//               );
//             })}
//           </ul>
//         </div>

//         <div className="showmenu" onClick={this.menuHandler}>
//           <i className="fa fa-bars" aria-hidden="true"></i>
//         </div>
//         {isMenuShow && (
//           <div className="backgroundmenu" onClick={this.menuHandler} />
//         )}
//       </div>
//     );
//   }
// }
