import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import ProjectsSection from "../components/Projects/Projects";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import data from "../assets/data.json";
import "./Projects.css";

const EventPage = () => {
  const location = useLocation();

  const baseURL = location.pathname.split("/")[1];

  const pageData = data[baseURL];
  return (
    <>
      <Navbar />
      <PageTitle title={pageData.title} backgroundClassName={"projects"} />
      <ProjectsSection description={pageData.description} projectsList={pageData.projectsList} />
      <Footer />
      <Scrollbar />
    </>
  );
};
export default EventPage;
