import React from "react";
import ImageCustom from "../Image";
import "./style.css";

const TeamSection = (props) => (
  <div className={`volunteer-area section-padding ${props.addclass}`}>
    <div className="container">
      <div className="row">
        <div className="col">
          <div
            className="section-title section-title2 text-center"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
      </div>
      <div className="volunteer-wrap">
        <div className="row">
          {props.volunteerImages.map(({ image }, index) => {
            return (
              <div
                key={`volunteer-${index}`}
                className="col col-lg-3 col-md-6 custom-grid col-12"
              >
                <div className="volunteer-item">
                  <div className="volunteer-img">
                    <ImageCustom src={image.source} alt={image.alt} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </div>
);

export default TeamSection;
