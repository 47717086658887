import React from "react";
import { NavLink, Link } from "react-router-dom";
import Image from "react-image-webp";
import Logo from "../../assets/images/logo-imani.png";
import LogoWebP from "../../assets/images/webp/logo-imani.webp";
import MobileMenu from "../../components/MobileMenu";
import "./style.css";

const Header = () => (
  <div className="middle-header">
    <div className="header-style-3">
      <div className="container">
        <div className="header-content">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 col-4">
              <div className="logo">
                <NavLink to="/inicio" title="Início" target={"_self"}>
                  <Image
                    src={Logo}
                    webp={LogoWebP}
                    alt="IMANI"
                    draggable="false"
                  />
                </NavLink>
              </div>
            </div>
            <div className="col-lg-8 d-lg-block d-none">
              <nav>
                <ul>
                  <li>
                    <NavLink
                      to="/quem-somos"
                      title="Quem somos"
                      activeclassname="active"
                    >
                      Quem somos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/projetos"
                      title="Projetos"
                      activeclassname="active"
                    >
                      Projetos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/donativo"
                      title="Como ajudar"
                      activeclassname="active"
                    >
                      Como ajudar
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/artigos-solidarios"
                      title="Artigos solidários"
                      activeclassname="active"
                    >
                      Artigos Solidários
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/parcerias"
                      title="Parcerias"
                      activeclassname="active"
                    >
                      Parcerias
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/contactos"
                      title="Contactos"
                      activeclassname="active"
                    >
                      Contactos
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-5 col-lg-2">
              <div className="make-different">
                <Link className="theme-btn" to="/donativo">
                  Faça a DIFERENÇA
                </Link>
              </div>
            </div>

            <div className="offset-1 col-md-2 col-sm-2 col-2">
              <MobileMenu />
            </div>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
