import React from "react";
import Slider from "react-slick";

import slider1 from "../../assets/images/homepage-slider-1.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

var settings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  fade: true,
};

const Hero = () => (
  <section className="hero-slider hero-style-1">
    <Slider {...settings}>
      <div className="slide">
        <div
          className="slide-inner"
          style={{ backgroundImage: `url(${slider1})` }}
        />
      </div>
    </Slider>
  </section>
);

export default Hero;
