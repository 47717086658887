import React from "react";
import Slider from "react-slick";
import ImageCustom from "../Image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.css";

const settings = {
  dots: true,
  arrows: false,
  speed: 1200,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  fade: false,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ProjectsPhotos = ({ photosList = [] }) => (
  <section className="partners-section section-padding">
    <div className="container">
      <div className="row">
        <div className="col col-xs-12">
          <div className="partner-grids partners-slider">
            {photosList && (
              <Slider {...settings}>
                {photosList.map((photo, index) => (
                  <div className="grid" key={`photo-${index}`}>
                    <ImageCustom
                      src={photo.image}
                      alt={photo.title}
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ProjectsPhotos;
