import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import Partners from "../components/Partners";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import data from "../assets/data.json";

const PartnersPage = () => {
  const location = useLocation();

  const baseURL = location.pathname.split("/")[1];

  const pageData = data[baseURL];
  return (
    <>
      <Navbar />
      <PageTitle backgroundClassName={"partners"} title={pageData.title} />
      <Partners
        description={pageData.description}
        partnersList={pageData.partnersList}
      />
      <Footer />
      <Scrollbar />
    </>
  );
};

export default PartnersPage;
