import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import SolidatiryArticles from "../components/SolidatiryArticles";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";

import data from "../assets/data.json";

const SolidatiryArticlesPage = () => {
  const location = useLocation();

  const baseURL = location.pathname.split("/")[1];

  const pageData = data[baseURL];

  return (
    <>
      <Navbar />
      <PageTitle backgroundClassName={"solitary"} title={pageData.title} />
      <SolidatiryArticles articles={pageData.articles} />
      <Footer />
      <Scrollbar />
    </>
  );
};

export default SolidatiryArticlesPage;
