import React from "react";
import Image from "react-image-webp";

const ImageCustom = ({ src, alt, draggable = false }) => (
  <Image
    src={require(`../../assets/images/${src}.jpg`)}
    webp={require(`../../assets/images/webp/${src}.webp`)}
    alt={alt}
    draggable={draggable}
  />
);

export default ImageCustom;
