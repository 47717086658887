import React from "react";
import { Link } from "react-router-dom";
import ImageCustom from "../../components/Image";
import "./Projects.css";

const ProjectsSection = ({ description, projectsList, addClass }) => (
  <div className={`volunteer-area section-padding ${addClass}`}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <div
            className="section-title section-title2 text-center"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>

      <div className="volunteer-wrap">
        <div className="row align-center">
          {projectsList.map((project) => {
            return (
              <div className="col col-md-4 custom-grid col-12">
                <div className="volunteer-item">
                  <Link to={project.link}>
                    <div className="volunteer-img">
                      <ImageCustom src={project.image} alt={project.title} />
                    </div>
                    <div className="volunteer-content">
                      <h2>{project.title}</h2>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </div>
);

export default ProjectsSection;
