import React from "react";
import { useLocation } from "react-router-dom";
import ImageCustom from "../components/Image";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import ProjectsPhotos from "../components/ProjectsPhotos";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import data from "../assets/data.json";

const ProjectApoioEducacionalPage = () => {
  const location = useLocation();

  const baseURL = location.pathname.split("/")[1];
  const currentURL = location.pathname.split("/")[2];

  const pageData = data[baseURL][currentURL];

  return (
    <>
      <Navbar />
      <PageTitle
        backgroundClassName="project-apoio-educacional"
        title={pageData.title}
      />
      <div className="projects">
        {pageData.blocks.map((block, index) => {
          return (
            <section
              key={`block-${index}`}
              className={`project-container ${index % 2 === 0 ? "orange" : ""}`}
            >
              <div className="row">
                <div className="col">
                  <div id="Description">
                    <div className="case-b-text row">
                      <div className="col-12 col-md-6 col-lg-4">
                        <div className="project-cause">
                          <div className="cause-img">
                            <ImageCustom
                              src={block.image.source}
                              alt={block.image.alt}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="vertical-align col-12 col-md-6 col-lg-8 offset-lg-0"
                        dangerouslySetInnerHTML={{ __html: block.description }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        })}

        <div className="container section-padding">
          <div className="row">
            <div className="col col-12">
              <div className="tp-case-content">
                <div className="tp-case-details-wrap">
                  <div className="tp-case-details-text">
                    <div className="case-bb-text">
                      <div className="row">
                        {pageData.columns.map((column, index) => {
                          return (
                            <div
                              key={`column-${index}`}
                              className="col-lg-4 col-md-6 col-12"
                            >
                              <div className="cause-item">
                                <div className="cause-top">
                                  <div className="cause-img">
                                    <ImageCustom
                                      src={column.image.source}
                                      alt={column.image.alt}
                                    />
                                  </div>
                                </div>
                                <div className="cause-text">
                                  <h3
                                    dangerouslySetInnerHTML={{
                                      __html: column.title,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProjectsPhotos photosList={pageData.photosList} />
      </div>
      <Footer />
      <Scrollbar />
    </>
  );
};
export default ProjectApoioEducacionalPage;
