import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import Contactpage from "../components/Contactpage/ContactPage";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";

const ContactPage = () => (
  <>
    <Navbar />
    <PageTitle backgroundClassName={"contacts"} title={"Contactos"} />
    <Contactpage />
    <Footer />
    <Scrollbar />
  </>
);
export default ContactPage;
