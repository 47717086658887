import React, { useState } from "react";
import ImageCustom from "../Image";
import Alert from "react-bootstrap/Alert";
import * as emailjs from "emailjs-com";

import "./style.css";

const Donate = ({ subTitle, donationsList }) => {
  const defaultDonationType = "punctual";

  const [mailInfo, setMailInfo] = useState({
    mailSend: false,
    mailError: false,
  });

  const [donationData, setDonationData] = useState({
    name: "",
    email: "",
    nif: "",
    address: "",
    howToKnow: "",
    donationValue: "",
    donationType: defaultDonationType,
    errors: {
      donationValue: "",
      donationType: "",
      name: "",
      email: "",
      nif: "",
      address: "",
      howToKnow: "",
    },
  });

  const validateForm = () => {
    let errors = {};
    let formatIsValid = true;

    const { name, nif, email, address, donationValue } = donationData;

    if (donationValue < 5) {
      errors.donationValue = "Selecione o valor do donativo";
      formatIsValid = false;
    }

    if (name.trim() === "" || name.trim().length < 3) {
      errors.name = "Digite o seu nome";
      formatIsValid = false;
    }

    if (email.trim() === "" || email.trim().length < 3) {
      errors.email = "Formato de email inválido";
      formatIsValid = false;
    }

    let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!pattern.test(email)) {
      errors.email = "Formato de email inválido";
      formatIsValid = false;
    }

    if (nif.trim() !== "" && nif.trim().length < 9) {
      errors.nif = "Digite um NIF válido";
      formatIsValid = false;

      if (address.trim() === "" || address.trim().length < 3) {
        errors.address = "Digite uma morada válida";
        formatIsValid = false;
      }
    }

    setDonationData({
      ...donationData,
      errors,
    });
    return formatIsValid;
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setDonationData({
      ...donationData,
      [name]: value,
      errors: {
        ...donationData.errors,
        [name]: "",
      },
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const {
      name,
      nif,
      email,
      donationType,
      donationValue,
      address,
      howToKnow,
    } = donationData;

    var templateParams = {
      from_email: email,
      from_name: name,
      from_nif: nif,
      from_address: address,
      from_howToKnow: howToKnow,
      reply_to: email,
      donation_type: donationType,
      donation_value: donationValue,
      to_name: "Associação IMANI",
    };

    emailjs
      .send(
        "gmail-service-rifsce8",
        "template_0mqjw4v",
        templateParams,
        "r-QdtkroXmlrIyR9w"
      )
      .then(
        (response) => {
          setMailInfo({ mailSend: true, mailError: false });

          // Clear form
          setDonationData({
            name: "",
            email: "",
            nif: "",
            address: "",
            howToKnow: "",
            donationValue: "",
            donationType: defaultDonationType,
            errors: {
              donationValue: "",
              donationType: "",
              name: "",
              email: "",
              nif: "",
              address: "",
              howToKnow: "",
            },
          });

          removeAllDonationValueSelectedClass();
        },
        (err) => {
          setMailInfo({ mailSend: false, mailError: true });
        }
      );
  };

  const successMessage = () => {
    const { mailSend, mailError } = mailInfo;

    if (mailSend) {
      return (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-success"
              dangerouslySetInnerHTML={{
                __html:
                  "<p>Informamos que recebemos o seu formulário.<br/>Pode fazer o seu donativo para: NIB PT50 0033-0000-45577576435-05 ( Millennium BCP ) ou MBWAY 938 834 722.<br/>Obrigado por não ficar indiferente! Juntos fazemos a diferença no MUNDO.</p>",
              }}
            />
          </div>
        </div>
      );
    }

    if (mailError) {
      return (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-danger"
              dangerouslySetInnerHTML={{
                __html:
                  "<b>Erro!</b><br/>Ocurreu um erro ao enviar o email. Tente novamente!",
              }}
            />
          </div>
        </div>
      );
    }
  };

  const onDonationTypeChanged = (e) => {
    setDonationData({
      ...donationData,
      donationType: e.currentTarget.value,
    });
  };

  const removeAllDonationValueSelectedClass = () => {
    const allChildElementsWithClass = document.querySelectorAll(
      ".donation-value-selected"
    );
    allChildElementsWithClass.forEach((element) => {
      element.classList.remove("donation-value-selected");
    });
  };

  return (
    <div className="tp-donation-page-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>{subTitle}</h1>
            <div className="tp-doanation-payment">
              <div className="tp-payment-area">
                <div className="row">
                  <div className="tp-payment-option" id="open4">
                    <div className="tp-payment-select">
                      <ul className="group">
                        <li className="donation">
                          <input
                            id="monthly"
                            type="radio"
                            name="donation-type"
                            value="monthly"
                            checked={donationData.donationType === "monthly"}
                            onChange={onDonationTypeChanged}
                          />
                          <label htmlFor="monthly">Mensal - Patrono</label>
                        </li>
                        <li className="donation">
                          <input
                            id="punctual"
                            type="radio"
                            name="donation-type"
                            value="punctual"
                            checked={donationData.donationType === "punctual"}
                            onChange={onDonationTypeChanged}
                          />
                          <label htmlFor="punctual">Pontual</label>
                        </li>
                      </ul>
                    </div>
                    {donationsList[donationData.donationType].title && (
                      <h5>{donationsList[donationData.donationType].title}</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="smartgrid col col-lg-4">
            <div className="col event-item other-value">
              <div className="event-text">
                <div className="event-right">
                  <h4>
                    Ou escolha outro valor
                    <input
                      type="number"
                      className="form-control"
                      name="name"
                      id="other-value"
                      step="10"
                      placeholder="0"
                      min="10"
                      autoCorrect="off"
                      autoComplete="off"
                      onChange={(e) => {
                        // Force to use numbers
                        e.target.value = e.target.value.replace(/[^0-9]*/g, "");
                        setDonationData({
                          ...donationData,
                          donationValue: e.target.value,
                          errors: {
                            ...donationData.errors,
                            donationValue: "",
                          },
                        });
                      }}
                      onFocus={removeAllDonationValueSelectedClass}
                    />
                    €
                  </h4>
                </div>
              </div>
            </div>
            {donationsList[donationData.donationType].donations.map(
              (donation, index) => (
                <button
                  className="col-6 col-lg-12 event-item"
                  key={`donation-${donationData.donationType}-${index}`}
                  onClick={(e) => {
                    // Remove all active class
                    removeAllDonationValueSelectedClass();

                    e.currentTarget.classList.add("donation-value-selected");

                    setDonationData({
                      ...donationData,
                      donationValue: donation.value,
                      errors: {
                        ...donationData.errors,
                        donationValue: "",
                      },
                    });
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-8 event-img">
                      <ImageCustom
                        src={donation.thumbnail}
                        alt={donation.value}
                      />
                    </div>
                    <div className="col-12 col-md-4 event-text">
                      <div className="event-l-text">
                        <h4>{donation.value}€</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h4>{donation.text}</h4>
                  </div>
                </button>
              )
            )}
          </div>
          <div className="col-lg-8">
            <form id="donationsForm">
              <div className="tp-donations-details">
                <h3>Quero doar</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Nome *"
                      value={donationData.name}
                      onChange={handleInputChange}
                    />

                    {donationData.errors.name && (
                      <Alert variant={"danger"}>
                        {donationData.errors.name}
                      </Alert>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="nif"
                      id="nif"
                      placeholder="NIF"
                      value={donationData.nif}
                      onChange={handleInputChange}
                    />
                    {donationData.errors.nif && (
                      <Alert variant={"danger"}>
                        {donationData.errors.nif}
                      </Alert>
                    )}
                  </div>
                  <div className="col-12 form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      id="address"
                      placeholder="Morada"
                      value={donationData.address}
                      onChange={handleInputChange}
                    />
                    {donationData.errors.address && (
                      <Alert variant={"danger"}>
                        {donationData.errors.addressFF}
                      </Alert>
                    )}
                  </div>
                  <div className="col-12 form-group clearfix">
                    <input
                      required
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email *"
                      value={donationData.email}
                      onChange={handleInputChange}
                    />
                    {donationData.errors.email && (
                      <Alert variant={"danger"}>
                        {donationData.errors.email}
                      </Alert>
                    )}
                  </div>

                  <div className="col-12 clearfix form-group form-how-to-know">
                    <select
                      className="form-control"
                      name="howToKnow"
                      value={donationData.howToKnow}
                      onChange={handleInputChange}
                    >
                      <option disabled={true} value="">
                        Como nos conheceu
                      </option>
                      <option value="Patrono">Patrono</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Members">Membros da Associação</option>
                      <option value="Flyer">Flyer</option>
                      <option value="Search">Pesquisa na Internet</option>
                      <option value="Other">Outro</option>
                    </select>

                    {donationData.errors.howToKnow && (
                      <Alert variant={"danger"}>
                        {donationData.errors.howToKnow}
                      </Alert>
                    )}
                  </div>
                </div>
              </div>
              <div className="submit-area sub-btn row">
                {donationData.errors.donationValue && (
                  <Alert variant={"danger"}>
                    {donationData.errors.donationValue}
                  </Alert>
                )}
                <div className="col-12 col-md-7">
                  <p>
                    Caso queira recibo de donativo é necessário o seu NIF e
                    Morada
                  </p>
                </div>
                <div className="col-12 col-md-4 text-right">
                  <button
                    type="submit"
                    className="theme-btn submit-btn"
                    onClick={submitForm}
                  >
                    Enviar »»
                  </button>
                </div>
              </div>
              {successMessage()}
            </form>
            <div className="tp-doanation-payment">
              <div className="tp-payment-area">
                <p>{donationsList[donationData.donationType].description}</p>
                <p>{donationsList[donationData.donationType].info}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
