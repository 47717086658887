import React from "react";
import classNames from "classnames";
import Slider from "react-slick";
import Image from "react-image-webp";
import balonCitationImg from "../../assets/images/balon-citation.png";
import balonCitationImgWebP from "../../assets/images/webp/balon-citation.webp";
import ballonHeartImg from "../../assets/images/ballon-heart.png";
import ballonHeartImgWebP from "../../assets/images/webp/ballon-heart.webp";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./style.css";

export default function Testimonial({ slideData = [], noBackground = false }) {
  const testimonialsSectionClass = classNames(
    `testimonials-section section-padding`,
    {
      [`no-background`]: noBackground === true,
    }
  );

  const slideClass = classNames(`grid`, {
    [`col-6`]: noBackground === true,
  });

  return (
    <section className={testimonialsSectionClass}>
      <div className="container">
        <div className="row">
          <div className="col offset-md-2">
            <div className="testimonials-slider">
              <div className="testimonial-content-active text-center">
                <Slider
                  slidesToShow={slideData.length}
                  swipeToSlide={true}
                  arrows={false}
                  fade={true}
                >
                  {slideData.map((data, index) => (
                    <div className={slideClass} key={`testimonial-${index}`}>
                      <p
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                      <div className="info">
                        <h5>{data.title}</h5>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      {noBackground && (
        <div className="shape1">
          <Image
            src={ballonHeartImg}
            webp={ballonHeartImgWebP}
            alt="bg ballon heart"
            draggable="false"
          />
        </div>
      )}
      <div className="testi-shape2">
        <Image
          src={balonCitationImg}
          webp={balonCitationImgWebP}
          alt="bg citation"
          draggable="false"
        />
      </div>
    </section>
  );
}
