import React from "react";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import Error from "../components/404";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";

const ErrorPage = () => (
  <>
    <Navbar />
    <PageTitle pageTitle={"404 Error!"} backgroundClassName={"error-page"} />
    <Error />
    <Footer />
    <Scrollbar />
  </>
);
export default ErrorPage;
