import React from "react";
import { Link } from "react-router-dom";
import Image from "react-image-webp";
import LogoWhite from "../../assets/images/logo-white.png";
import LogoWhiteWebP from "../../assets/images/webp/logo-white.webp";
import "./footer.css";

const Footer = () => (
  <footer className="tp-site-footer">
    <div className="tp-upper-footer">
      <div className="container">
        <div className="row">
          <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="widget about-widget">
              <div className="footer-logo widget-title">
                <Image
                  src={LogoWhite}
                  webp={LogoWhiteWebP}
                  alt={"logo"}
                  className={"mx-auto d-block"}
                />
              </div>
              <ul className="nav justify-content-center">
                <li>
                  <a
                    href="https://www.instagram.com/imani.association"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i className="ti-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/imani.association"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i className="ti-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCLyRQrSRkygZs1t8IgxHmMw"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i className="ti-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col offset-md-0 col-md-6 offset-sm-3 col-sm-6 col-12">
            <div className="widget link-widget">
              <ul className="text-center justify-content-center">
                <li>
                  <Link to="/quem-somos">Quem Somos</Link>
                </li>
                <li>
                  <Link to="/projetos">Projetos</Link>
                </li>
                <li>
                  <Link to="/donativo">Como Ajudar</Link>
                </li>
                <li>
                  <Link to="/artigos-solidarios">Artigos Solidários</Link>
                </li>
                <li>
                  <Link to="/parcerias">Parcerias</Link>
                </li>
                <li>
                  <Link to="/contactos">Contactos</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-lg-3 col-12">
            <div className="widget text-center instagram">
              <div className="widget-title">
                <h3>JUNTE-SE A NÓS E APOIE</h3>
              </div>
              <Link className="theme-btn" to="/donativo">
                Faça a DIFERENÇA
                <i className="fa fa-angle-double-right" aria-hidden="true" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="tp-lower-footer">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <p className="copyright">
              &copy; 2022 Associação IMANI. Todos os direitos reservados
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
