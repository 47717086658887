import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import Features from "../components/Features";
import TeamSection from "../components/team";
import Testimonial from "../components/Testimonial";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import data from "../assets/data.json";

const AboutPage = () => {
  const location = useLocation();

  const baseURL = location.pathname.split("/")[1];

  const pageData = data[baseURL];

  return (
    <>
      <Navbar />
      <PageTitle backgroundClassName="about" title={pageData.title} />
      <TeamSection
        description={pageData.description}
        volunteerImages={pageData.volunteers}
      />
      <Features
        description={pageData.features.description}
        image={pageData.features.image}
      />
      <Testimonial slideData={pageData.slideData} noBackground />
      <Footer />
      <Scrollbar />
    </>
  );
};

export default AboutPage;
