import React from "react";
import Image from "react-image-webp";
import kidsPencilHand from "../../assets/images/homepage-kids-pencil-hand.jpeg";
import kidsPencilHandWebP from "../../assets/images/webp/homepage-kids-pencil-hand.webp";
import shape from "../../assets/images/shape2.png";
import shapeWebP from "../../assets/images/webp/shape2.webp";
import "./style.css";

const About = () => (
  <div className="about-area">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-5">
          <div className="about-cercle">
            <div className="about-img">
              <Image
                src={kidsPencilHand}
                webp={kidsPencilHandWebP}
                alt="Menina lápis na mão"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 offset-lg-1 grid  col-md-12 col-12">
          <div className="about-text">
            <div className="section-title">
              <h1>
                IMANI = <span>Acreditar</span> <br />
                <span>Moçambique</span>
              </h1>
            </div>
            <p>
              Projetos de Cooperação e Desenvolvimento desenvolvidos em parceria
              com as comunidades locais
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="ab-shape">
      <Image src={shape} webp={shapeWebP} alt="bg-shape" />
    </div>
  </div>
);

export default About;
