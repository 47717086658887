import React from "react";
import ContactForm from "../ContactFrom/ContactForm";
import "./ContactPage.css";

const Contactpage = () => (
  <section className="contact-pg-contact-section section-padding">
    <div className="container">
      <div className="row">
        <div className="col col-lg-6 col-12">
          <div className="section-title-s3 section-title-s5">
            <h2>Associação IMANI</h2>
          </div>
          <div className="contact-details">
            <ul>
              <li>
                <div className="icon">
                  <i className="ti-mobile"></i>
                </div>
                <h5>Portugal</h5>
                <p>
                  Telefone <a href="tel:00351916831840">+351 916 831 840</a>
                </p>
              </li>
              <li>
                <div className="icon">
                  <i className="ti-mobile"></i>
                </div>
                <h5>MOÇAMBIQUE</h5>
                <p>
                  Telefone: <a href="tel:00258846552179">+258 846 552 179</a>
                </p>
              </li>
              <li>
                <div className="icon">
                  <i className="ti-email"></i>
                </div>
                <h5>Email</h5>
                <p>associacao.imani@gmail.com</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="col col-lg-6 col-12">
          <div className="contact-form-area">
            <div className="section-title-s3 section-title-s5">
              <h2>Formulário de contacto</h2>
            </div>
            <div className="contact-form">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Contactpage;
