import React, { useState } from "react";
import * as emailjs from "emailjs-com";
import Alert from "react-bootstrap/Alert";

const ContactForm = () => {
  const [mailInfo, setMailInfo] = useState({
    mailSend: false,
    mailError: false,
  });

  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    errors: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setEmailData({
      ...emailData,
      [name]: value,
      errors: {
        ...emailData.errors,
        [name]: "",
      },
    });
  };

  const validateForm = () => {
    let errors = {};
    let formatIsValid = true;

    const { name, email, subject, message } = emailData;

    if (name.trim() === "" || name.trim().length < 3) {
      errors.name = "Digite o seu nome";
      formatIsValid = false;
    }

    if (subject.trim() === "" || subject.trim().length < 3) {
      errors.subject = "Digite um assunto";
      formatIsValid = false;
    }

    if (email.trim() === "" || email.trim().length < 3) {
      errors.email = "Formato de email inválido";
      formatIsValid = false;
    }

    let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!pattern.test(email)) {
      errors.email = "Formato de email inválido";
      formatIsValid = false;
    }

    if (message.trim() === "" || message.trim().length < 10) {
      errors.message = "Deixa uma mensagem mais extensa";
      formatIsValid = false;
    }

    setEmailData({
      ...emailData,
      errors,
    });
    return formatIsValid;
  };

  const sendMessage = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const { name, email, subject, message } = emailData;

    var templateParams = {
      from_email: email,
      from_name: name,
      reply_to: email,
      from_subject: subject,
      message_html: message,
      to_name: "Associação IMANI",
    };

    emailjs
      .send(
        "gmail-service-rifsce8",
        "template_sit25mb",
        templateParams,
        "r-QdtkroXmlrIyR9w"
      )
      .then(
        (response) => {
          setMailInfo({ mailSend: true, mailError: false });

          // Clear form
          setEmailData({
            name: "",
            email: "",
            subject: "",
            message: "",
            errors: {
              name: "",
              email: "",
              subject: "",
              message: "",
            },
          });
        },
        (err) => {
          setMailInfo({ mailSend: false, mailError: true });
        }
      );
  };

  const successMessage = () => {
    const { mailSend, mailError } = mailInfo;

    if (mailSend) {
      return (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-success"
              dangerouslySetInnerHTML={{
                __html:
                  "<b>Enviado!</b><br/>Recebemos a tua mensagem, prometemos ser breves na resposta.",
              }}
            ></div>
          </div>
        </div>
      );
    }

    if (mailError) {
      return (
        <div className="row">
          <div className="col-12">
            <div
              className="alert alert-danger"
              dangerouslySetInnerHTML={{
                __html:
                  "<b>Erro!</b><br/>Ocurreu um erro ao enviar o email. Tente novamente!",
              }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <form className="form">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <label htmlFor="name">Nome</label>
              <input
                required
                value={emailData.name}
                onChange={handleInputChange}
                type="text"
                name="name"
              />
            </div>
            {emailData.errors.name && (
              <Alert variant={"danger"}>{emailData.errors.name}</Alert>
            )}
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <label htmlFor="email">E-mail</label>
              <input
                required
                value={emailData.email}
                onChange={handleInputChange}
                type="email"
                name="email"
              />
              {emailData.errors.email && (
                <Alert variant={"danger"}>{emailData.errors.email}</Alert>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <label htmlFor="email">Assunto</label>
              <input
                value={emailData.subject}
                onChange={handleInputChange}
                required
                type="text"
                name="subject"
              />
            </div>
            {emailData.errors.subject && (
              <Alert variant={"danger"}>{emailData.errors.subject}</Alert>
            )}
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              <label htmlFor="message">Mensagem</label>
              <textarea
                required
                defaultValue={emailData.message}
                autoComplete={"off"}
                name="message"
                onChange={handleInputChange}
              />
            </div>
            {emailData.errors.message && (
              <Alert variant={"danger"}>{emailData.errors.message}</Alert>
            )}
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn" onClick={sendMessage}>
                Enviar mensagem
              </button>
            </div>
          </div>
        </div>
      </form>
      {successMessage()}
    </>
  );
};
export default ContactForm;
