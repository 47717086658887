import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const Error = () => (
  <section className="error-404-section section-padding">
    <div className="container">
      <div className="row">
        <div className="col col-xs-12">
          <div className="content clearfix">
            <div className="error-message">
              <h1>Oops! Página não encontrada!</h1>
              <p>
                Lamentamos mas não foi possível encontrar a página que procura.
                <br />
                Isto pode ter ocorrido por um destes motivos:
                <ul>
                  <li>A página está temporariamente desativada</li>
                  <li>A página mudou de endereço</li>
                  <li>O link em que clicou está errado</li>
                  <li>Não inseriu corretamente o endereço no seu browser</li>
                </ul>
                <br />
                Verifique e volte a tentar. <br /> Se o problema persistir,
                contacte-nos.
              </p>
              <Link to="/" className="theme-btn">
                <i className="fa fa-angle-double-left" /> Voltar ao início
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default Error;
