import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar";
import PageTitle from "../components/pagetitle";
import Donate from "../components/Donate";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import data from "../assets/data.json";

const DonatePage = () => {
  const location = useLocation();

  const baseURL = location.pathname.split("/")[1];

  const pageData = data[baseURL];

  return (
    <>
      <Navbar />
      <PageTitle backgroundClassName={"donate"} title={pageData.title} />
      <Donate
        subTitle={pageData.subTitle}
        donationsList={pageData.donationsList}
      />
      <Footer />
      <Scrollbar />
    </>
  );
};
export default DonatePage;
