import React from "react";
import ImageCustom from "../Image";

import "./style.css";

const Features = (props) => (
  <div className="features-area">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-5">
          <div
            className="features-text"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
        <div className="col-lg-7">
          <div className="features-wrap">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="features-item-2">
                  <ImageCustom src={props.image.source} alt={props.image.alt} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Features;
