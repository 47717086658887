import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isWebpSupported } from "react-image-webp/dist/utils";

import "./App.css";
import Router from "./Router";

const App = () => {
  useEffect(() => {
    document.body.classList.add(
      `${isWebpSupported() ? "webp-supported" : "no-webp-supported"}`
    );
  }, []);

  return (
    <div className="App" id="scrool">
      <Router />
      <ToastContainer />
    </div>
  );
};

export default App;
